<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{ $t("message.online_works ") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <div
            v-if="managers"
            class="pb-2"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            <b>Ma'sul Xodimlar: </b>
            
            <span v-for="manager in managers" :key="manager.id"
              >{{ manager.name }} {{ manager.last_name }},
            </span>
          </div>
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="staff_id">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.staff") }}</span
                  >
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.reason") }}</span
                  >
                  <el-input
                    :class="mode ? 'input__day' : 'input__night'"
                    :size="'medium'"
                    :placeholder="$t('message.reason')"
                    :inputValue="form.reason"
                    :type="'textarea'"
                    v-model="form.reason"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-divider content-position="left">{{
                  $t("message.datas")
                }}</el-divider>
                <div class="seloction mb20 ">
                  <el-date-picker
                    class="w-100"
                    v-model="form.date"
                    type="date"
                    :picker-options="dateRangeOptions1"
                    :placeholder="$t('message.datas')"
                    popper-class="my-data-khan"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-divider content-position="left">{{
                  $t("message.time")
                }}</el-divider>
                <div class="datetime-start-time mb20 time_picer">
                  <el-time-picker
                    v-model="form.work_time"
                    is-range
                    range-separator="-"
                    :start-placeholder="$t('message.from')"
                    :end-placeholder="$t('message.to')"
                    value-format="HH:mm:ss"
                  />
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [drawer, form],
  name: "PenaltyController",
  components: { selectStaff },
  data() {
    return {
      managers: null,
      penalty_reason: null,
      imageList: [],
      image: "",
      disable_date: true,
      disable_time: true,
      dateRangeOptions1: {
        firstDayOfWeek: 1
      }
    };
  },
  computed: {
    ...mapGetters({
      rules: "onlineWork/rules",
      model: "onlineWork/model",
      columns: "onlineWork/columns",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      removeFile: "onlineWork/removeFile",
      save: "onlineWork/store"
    }),
    date(val) {
      if (val == "date") {
        (this.disable_date = false), (this.disable_time = false);
      }
    },
    createImageList(file) {
      this.form.image = file.raw;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    submit(close = true) {
      this.loadingButton = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.fileList = [];
              this.imageList = [];
              this.loadingButton = false;
              this.parent().listChanged();
              console.log(res);
              if (close) this.close();
              if (res.status == 201) {
                this.$alert(res);
              }
            })
            .catch(err => {
              console.log(err);
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    setName(name) {
      var data = {
        id: 1,
        penalty_id: 2
      };
      this.removeFile(data).then(res => {
        console.log(res);
      });
      // console.log(name);
    }
  }
};
</script>

<style lang="scss">
.active {
  .el-upload-list__item-status-label {
    display: inline-block !important;
  }
}
.el-upload-list__item:hover {
  .el-upload-list__item-status-label {
    display: none !important;
  }
}
.my-upload {
  min-height: 200px !important;
}
.my-upload .el-upload-list {
  position: relative;
  z-index: 2;
}
.my-upload .el-upload.el-upload--picture-card {
  position: absolute;
  left: 0px !important;
  z-index: 1;
  width: 146px;
  line-height: 150px;
  vertical-align: top;
}
.my-upload .upload-demo {
  position: relative;
}

.upload__mynight {
  .el-upload.el-upload--picture-card {
    background-color: transparent;
  }
}
</style>
